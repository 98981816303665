<template>
  <p class="mb-6 pb-3">
    {{CONTENTS.explanation}}
  </p>
  <div class="box box-shadow-none has-background-theme-grey pt-3 px-4">
    <p class="is-size-6" v-for="(text, index) in CONTENTS.texts" :key="index">
      {{text}}&nbsp;
    </p>
    <a href="https://www.pref.osaka.lg.jp/hodo/index.php?site=fumin&pageId=44550" target="_blank">詳しくは大阪府HP</a>
    <p class="has-text-centered is-size-5 has-text-weight-bold">募集期間 2022年6月1日(水)ー9月15日(木）</p>
  </div>
</template>

<script>

const CONTENTS = {
  "explanation": "『木と触れ合う木育推進事業』とは、『大阪府産材』を使用した机や椅子、下駄箱、など、子どもが触れる家具とおもちゃを対象とした補助金です。",
  "texts": [
    "2022年度 大阪府補助事業「木とふれあう木育推進事業」",
    "補助対象 「大阪府内産木材」を使用した机・椅子・ロッカー・棚・下駄箱・おもちゃ等",
    "",
    "大阪府内に所在する子育て施設さまが「大阪府内産木材」を使った木製品の導入に要する費用の半分（上限50万円）を大阪府が補助します。",
    "",
    "＊対象施設において過去に本事業を実施していないこと",
    "＊応募多数の場合は抽選になります",
    
  ]
};

export default {
  name: 'parts-about-grant',
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
</style>
