<template>
<div class="card box-shadow-none is-hidden-tablet" v-for="(item, index) in INTERVIEWS" :key="index">
  <div class="card-image">
    <figure class="image">
      <img class="object-fit-contain" :src="item.src" :alt="item.alt">
    </figure>
  </div>
  <div class="card-content">
    <div class="content">
      <p class="title is-5 has-text-theme-green">{{item.title}}</p>
      <p class="is-size-7 lh-4_1" v-html="item.text"></p>
    </div>
  </div>
</div>

<div class="columns is-gapless is-hidden-mobile my-6" :class="((index % 2) === 1) ? 'is-flex-direction-row-reverse': ''" v-for="(item, index) in INTERVIEWS" :key="index">
  <div class="column">
    <figure class="image">
      <img class="object-fit-contain" :src="item.src" :alt="item.alt">
    </figure>
  </div>
  <div class="column has-background-white mt-20p z-1" :class="((index % 2) === 1) ? 'mr-7n': 'ml-7n'">
    <div class="content p-5">
      <p class="title is-5 has-text-theme-green">{{item.title}}</p>
      <p class="is-size-7 lh-4_1" v-html="item.text"></p>
    </div>
  </div>
</div>
</template>

<script>
//import config from './../config.json';

const INTERVIEWS = [
  {
    src: './static_img/interview_01.jpg',
    alt: '山崎保育園様',
    title: '山崎保育園様',
    text: '『まとめ』（オリジナル家具）<br>ちょうどゲートを作り替えようと思っていたんですけど、金額が高くて迷って<br>いたんですよ。そんな時にDMで補助金の案内があって<br>『もしかしたら、私たちも申請できるかな』って問い合わせてみました。<br>他の施設様の素敵な事例を拝見して安心できました。<br>頼んでみると、手厚くサポートしていただきました。子どもたちも<br>『木の香りがいい匂い』って口を揃えて先生たちに伝えてくれます。<br>あとは毎日何度も使うので、木の手触りが気持ちがよくて。<br>色んな所から喜びの声が聞こえるので、東野材木店さんにお願いしてよかったと感じています。'
  },
  {
    src: './static_img/interview_02.jpg',
    alt: 'ハッピーチルドレン保育園（IKONIH）',
    title: 'ハッピーチルドレン保育園（IKONIH）',
    text: '・IKONIHを選んで、一番良かった事はなんですか？<br>1番は大阪の木を使っているという事です。地元の木を使って、山を活性化させることに貢献できたと思います。シンプルなものであるが故に、子どもたちの想像力や思考力を鍛える。こういうものが身近に遊び道具としてある。ということが嬉しい。<br><br>・ひのき球プールを使ってみてはいかがですか？<br>子どもの機嫌が悪くなると先生が連れて行って、プールの中で遊ばせて気持ちを落ち着かせていました。中に入って泣いている子はあまりみないですね。肌ざわり・手触りが良いから、これらが子どもたちの感情を落ち着かせているんだと思います。'
  },
  {
    src: './static_img/interview_03.jpg',
    alt: 'らうらうベビールーム保育園',
    title: 'らうらうベビールーム保育園',
    text: '・東野材木店に頼んでよかったなと思ったこと<br>すぐに対応していただけること。スピード感があって良かったです。<br>補助金の情報があるという事も、最初から教えてくれましたよね。<br>保育博で出会った時、他にも木製の柵を展示している会社がありましたが、<br>そこでは補助金の説明が無かったので。その話を教えてくれて良かった。<br>自分達が不安に思わないように対応してくれたことが良かったです。<br>他の会社では「申請はそっちでしてくださいね」と言われてもおかしくないじゃないですか？<br>書類をちゃんとチェックしてくれたり、申請までの負担がかなり減ったのが楽だった。<br>申請をしなければならない・・・という時に、サポートがある/ないではかなり違います。'
  },
];

export default {
  name: 'parts-interview',
  components: {
  },
  data() {
    return {}
  },
  computed: {
    INTERVIEWS() {
      return INTERVIEWS;
    }
  }
}
</script>

<style scoped>
</style>
