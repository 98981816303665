<template>
  <PartsHeader/>
  
  <PartsFirstView/>
  
  <article id="about-circumstance" class="container">
    <section class="inner lowered">
      <PartsAboutCircumstance/>
    </section>
  </article>
  
  <article id="about-us" class="container js-scroll fade-in-bottom">
    <section class="inner raised">
      <PartsAboutUs/>
    </section>
  </article>
  
  <article id="about" class="container js-scroll fade-in-bottom">
    <section class="outer has-text-centered">
      <h2 class="title is-4 has-background-theme-green has-text-white is-inline-block p-4 px-5  sp-title">補助金で取り組める木育設備</h2>
    </section>
    <section class="inner">
      <PartsAboutFullOrder/>
    </section>
  </article>
  
  <article class="container js-scroll fade-in-bottom">
    <section class="inner lowered">
      <PartsAboutSemiOrder/>
    </section>
    
    <div class="ikonie js-scroll fade-in-bottom">
      <section class="inner py-0">
        <PartsAboutSemiOrderBrand/>
      </section>
    </div>
  </article>
  
  <article id="facilities" class="container js-scroll fade-in-bottom">
    <section class="outer has-text-centered">
      <h2 class="title is-4 has-background-theme-green has-text-white is-inline-block p-4 px-5  sp-title">木育商品事例</h2>
    </section>
    <section class="inner raised lowered">
      <PartsDetail :contents="config.FILL_ORDER_CONTENTS" :chunk-num="3" :is-fixed="true" class="is-hidden-mobile" />
      <PartsDetail :contents="config.FILL_ORDER_CONTENTS" :chunk-num="1" class="is-hidden-tablet" />
    </section>
  </article>

  <article id="facilities-semi-order" class="container js-scroll fade-in-bottom">
    <section class="inner lowered">
      <PartsDetail :contents="config.SEMI_ORDER_CONTENTS" :chunk-num="1" :is-horizontal="true" class="is-hidden-mobile" />
      <PartsDetail :contents="config.SEMI_ORDER_CONTENTS" :chunk-num="1" class="is-hidden-tablet" />
      <h3 class="is-over-under-line mb-6">
        <p class="is-size-6 lh-4">子どもたちは保育施設で家より長く過ごします。子どもたちが落ち着いて過ごす空間に木製品は最適だと私たちは思っています。</p>
      </h3>
    </section>
  </article>

  <article id="flow" class="container js-scroll fade-in-bottom">
    <section class="outer has-text-centered">
      <h2 class="title is-4 has-background-theme-green has-text-white is-inline-block p-4 px-5  sp-title">補助金を利用した木育設備導入の流れ</h2>
    </section>
    <section class="inner raised">
      <PartsFlow/>
    </section>
  </article>
  
  <article class="container js-scroll fade-in-bottom">
    <section class="inner">
      <PartsAccordionBox :is-show="isShowAboutOsakazai" @toggle="isShowAboutOsakazai = !isShowAboutOsakazai;" class="is-align-items-center is-justify-content-center">
        <template v-slot:title>
          <div class="has-text-centered">未来につなぐ大阪府内産木材</div>
        </template>
        <template v-slot:body>
          <PartsAboutOsakazai/>
        </template>
      </PartsAccordionBox>
    </section>
  </article>
  
  <article id="interview" class="container js-scroll fade-in-bottom">
    <section class="outer has-text-centered">
      <h2 class="title is-4 has-background-theme-green has-text-white is-inline-block p-4 px-5  sp-title">インタビュー</h2>
    </section>
    <section class="inner raised lowered">
      <PartsInterview/>
    </section>
  </article>
  
  <article id="detail" class="container js-scroll fade-in-bottom">
    <section class="outer has-text-centered">
      <h2 class="title is-4 has-background-theme-green has-text-white is-inline-block p-4 px-5  sp-title">補助金詳細案内</h2>
    </section>
    <section class="inner mt-6 pt-3">
      <PartsAboutGrant/>
    </section>
  </article>

  <article id="faq" class="container js-scroll fade-in-bottom">
    <section class="outer has-text-centered">
      <h2 class="title is-4 has-background-theme-green has-text-white is-inline-block p-4 px-5  sp-title">よくある質問</h2>
    </section>
    <section class="inner raised">
      <PartsFaqLink/>
    </section>
  </article>

  <article id="consultation" class="container js-scroll fade-in-bottom">
    <section class="outer has-text-centered">
      <h2 class="title is-4 has-background-theme-green has-text-white is-inline-block p-4 px-5  sp-title">補助金を使った木育施設・設備導入相談</h2>
    </section>
    <section class="inner pt-6 mt-3">
      <h3 class="is-over-under-line mb-6">
        <p class="is-size-6 lh-4_1">『大阪府産材』を使った木製品の導入に対する費用の半分（上限５０万円）を大阪府が補助</p>
      </h3>
      <PartsConsultation/>
    </section>
  </article>
  
  <article id="contact" class="container js-scroll fade-in-bottom">
    <section class="inner">
      <PartsAccordionBox :is-show="isShowContact" @toggle="isShowContact = !isShowContact;" class="is-align-items-center is-justify-content-center">
        <template v-slot:title>
          <div class="has-text-centered">補助金を使った木育設備・設備導入相談フォーム</div>
        </template>
          <template v-slot:body>
           <h3 class="is-over-under-line my-6">
            <p class="title is-6 mb-2">申請期限まで余裕がない場合などお急ぎの場合はお電話ください</p>
            <p class="title is-4">電話お問い合わせ先&emsp;TEL: 06-6696-7551</p>
          </h3>
          <PartsContactIFrame/>
        </template>
      </PartsAccordionBox>
    </section>
  </article>

  <PartsFooter/>
</template>

<script>
import config from './config.json';  

import PartsHeader from './components/PartsHeader.vue';
import PartsFirstView from './components/PartsFirstView.vue';
import PartsAboutCircumstance from './components/PartsAboutCircumstance.vue';
import PartsAboutUs from './components/PartsAboutUs.vue';
import PartsAboutFullOrder from './components/PartsAboutFullOrder.vue';
import PartsAboutSemiOrder from './components/PartsAboutSemiOrder.vue';
import PartsAboutSemiOrderBrand from './components/PartsAboutSemiOrderBrand.vue';
import PartsDetail from './components/PartsDetail.vue';
import PartsFlow from './components/PartsFlow.vue';
import PartsAboutOsakazai from './components/PartsAboutOsakazai.vue';
import PartsInterview from './components/PartsInterview.vue';
import PartsAboutGrant from './components/PartsAboutGrant.vue';
import PartsFaqLink from './components/PartsFaqLink.vue';
import PartsConsultation from './components/PartsConsultation.vue';
import PartsContactIFrame from './components/PartsContactIFrame.vue';
import PartsFooter from './components/PartsFooter.vue';
import PartsAccordionBox from './components/PartsAccordionBox.vue';

export default {
  name: 'App',
  components: {
    PartsHeader,
    PartsFirstView,
    PartsAboutCircumstance,
    PartsAboutUs,
    PartsAboutFullOrder,
    PartsAboutSemiOrder,
    PartsAboutSemiOrderBrand,
    PartsDetail,
    PartsFlow,
    PartsAboutOsakazai,
    PartsInterview,
    PartsAboutGrant,
    PartsFaqLink,
    PartsConsultation,
    PartsContactIFrame,
    PartsFooter,
    PartsAccordionBox,
  },
  data() {
    return {
      isShowContact: true,
      isShowAboutOsakazai: true,
    };
  },
  computed: {
    config() {
      return config;
    }
  }
}
</script>

<style scoped>
.ikonie {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("./assets/img/about_semi_order_background.jpg");
}

.ikonie {
  margin-bottom:9rem;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
.ikonie {
  margin-bottom:6rem;
}
}
 
@media all and (min-width: 768px) and (max-width: 1024px) {
.ikonie {
  margin-bottom:6rem;
}
} 
@media all and (min-width: 480px) and (max-width: 768px) {
.ikonie {
  margin-bottom:3rem;
}
}
 
@media all and (max-width: 480px) {
.ikonie {
  margin-bottom:3rem;
}
}
</style>
