<template>
  <div class="" :class="$attrs.class">
    <transition name="fade">
      <div v-show="contentsByPagenation[pageIndex].length">
        <div class="columns is-mobile is-justify-content-center">
          <div :class="`is-${columnWidth}`" class="column" v-for="(item, index) in contentsByPagenation[pageIndex]" :key="index">
    
            <div class="card box-shadow-none" v-if="!isHorizontal">
              <div class="card-image is-clickable" @click="modalShow(item)">
                <figure class="image">
                  <img class="object-fit-contain" :src="item.src" :alt="item.alt">
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="title is-7 has-text-theme-green">{{item.caption}}</p>
                  <p class="title is-5 mt-3 mb-4">{{item.title}}</p>
                  <p v-html="item.text"></p>
                </div>
              </div>
            </div>
            
            <div class="columns is-gapless is-mobile" v-else>
              <div class="column">
                <figure class="image">
                  <img class="object-fit-contain" :src="item.src" :alt="item.alt">
                </figure>
              </div>
              <div class="column is-flex">
                <div class="content has-background-theme-grey p-3 w-100">
                  <p class="title is-7 has-text-theme-green">{{item.caption}}</p>
                  <p class="title is-5 mt-3 mb-4">{{item.title}}</p>
                  <p v-html="item.text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    
    <div class="is-flex is-justify-content-center is-align-items-center mt-5">
      <span :class="pageIndex > 0 ? 'has-text-grey': 'has-text-grey-light'" @click="prevPage" class="icon is-large is-clickable" >
        <i class="fas fa-2x fa-angle-left"></i>
      </span>
      <span class="is-size-5">{{ pageIndex < 0 ? prevPageIndex : pageIndex+1 }} / {{ contentsByPagenation.length }}</span>
      <span :class="pageIndex < (contentsByPagenation.length - 1) ? 'has-text-grey': 'has-text-grey-light'" @click="nextPage" class="icon is-large is-clickable" >
        <i class="fas fa-2x fa-angle-right"></i>
      </span>
    </div>
    <slot/>
  </div>
  <teleport to="body">
  <div class="modal" :class="show ? 'is-active': ''">
    <div class="modal-background"></div>
    <div class="modal-content">
      <p class="image" v-if="popup">
        <img class="object-fit-contain" :src="popup.src" :alt="popup.alt">
      </p>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="show = !show"></button>
  </div>
  </teleport>
</template>

<script>
import chunk from 'lodash/chunk';
//import { nextTick } from 'vue';

export default {
  name: 'parts-detail',
  props: {
    chunkNum: {
      type: Number,
      default: 3,
    },
    contents: {
      type: Array,
      default: () => [],
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      prevPageIndex: 0,
      pageIndex: 0,
      isActive: true,
      show: false,
      popup: null,
    };
  },
  computed: {
    contentsByPagenation() {
      return chunk(this.contents, this.chunkNum);
    },
    columnWidth() {
      if (this.isFixed) {
        return 4;
      } else {
        return (12 / this.contentsByPagenation[this.pageIndex].length);
      } 
    }
  },
  methods: {
    modalShow(item) {
      this.show = true;
      this.popup = item;
    },
    viewChange(newPageIndex) {
      this.prevPageIndex = this.pageIndex;
      this.pageIndex = -1;
      setTimeout(() => {
        this.pageIndex = newPageIndex;
      }, 500);
    },
    prevPage() {
      if (this.pageIndex > 0) {
        const newPageIndex = this.pageIndex - 1;
        this.viewChange(newPageIndex);
      }
    },
    nextPage() {
      if (this.pageIndex < (this.contentsByPagenation.length - 1)) {
        const newPageIndex = this.pageIndex + 1;
        this.viewChange(newPageIndex);
      }
    }
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
