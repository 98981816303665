<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item ml-6 is-hidden-mobile" href="/">
        <img class="object-fit-contain" src="../assets/img/logo.gif" width="294" height="128" style="max-height: none;">
      </a>
      <a class="navbar-item is-hidden-tablet m-auto" href="/">
        <img class="object-fit-contain" src="../assets/img/logo.gif" width="294" height="128" style="max-height: none;">
      </a>
  
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" :data-target="`nabvar-${uid}`">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div :id="`nabvar-${uid}`" class="navbar-menu">
      <div class="navbar-end">
        <a class="navbar-item px-0 menu__item" :class="menu['header-class']" :href="menu.href" v-for="menu in menus" :key="menu.label">
          {{menu.label}}
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { getCurrentInstance } from 'vue';

import config from './../config.json';

export default {
  name: 'parts-header',
  setup () {
    const uid = getCurrentInstance().uid;
  // ...
    return {
      uid
    };
  },
  data() {
    return {
      menus: config.MENUS,
    }
  },
}
</script>