<template>
  <div class="js-scroll fade-in-bottom pt-6" v-for="(item, index) in CONTENTS" :key="index">
    <div class="columns is-gapless">
      <div class="column">
        <figure class="image">
          <img class="object-fit-contain" :src="item.src_left" :alt="item.alt_left">
        </figure>
      </div>
      <div class="column">
        <figure class="image">
          <img class="object-fit-contain" :src="item.src_right" :alt="item.alt_right">
        </figure>
      </div>
    </div>
    <h3 class="title is-3 py-6 my-6 has-text-centered">{{item.title}}</h3>
    <p class="px-6 mx-6 is-size-6 lh-4_2 p-3-mobile m-3-mobile has-text-centered" v-html="item.text"></p>
  </div>
</template>

<script>
//import config from './../config.json';

const CONTENTS = [
  {
    "src_right": "./static_img/osakasanzai_right.jpg",
    "alt_right": "大阪産材　右",
    "src_left": "./static_img/osakasanzai_left.jpg",
    "alt_left": "大阪産材　左",
    "title": "ほんものの木。使うならおおさかの木",
    "text": "大阪には、先人たちが命がけで繋いできた『確かな山』があります。<br><br>地元で育った木を使って、地元の子育て施設で次世代の子どもたちが成長する。<br>そしてそれは山の健康維持につながります。<br><br>健康な山でまた木が成長して、未来の子どもたちに<br>資源として受け継がれていくのです。<br><br>補助金を機会に大阪で育った木を選び、森を未来の子どもたちに<br>繋いでいきませんか。",
  }
];

export default {
  name: 'parts-about-osakazai',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
</style>
