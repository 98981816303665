<template>
  <button @click="$emit('click')" type="button py-2 px-3" class="button is-normal is-rounded has-background-black has-text-white is-hidden-mobile" :class="$attrs.class"><slot/>
    <span class="icon ml-2"><i class="fas fa-angle-right"></i></span>
  </button>
  <button @click="$emit('click')" type="button py-3 px-3 mx-3" class="button is-normal is-rounded has-background-black has-text-white w-100 is-hidden-tablet" :class="$attrs.class"><slot/>
    <span class="icon ml-2"><i class="fas fa-angle-right"></i></span>
  </button>
</template>

<script>
export default {
  name: 'parts-button-black',
  data() {
    return {
    };
  }
}
</script>

<style scoped>
</style>
