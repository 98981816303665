<template>
  <iframe frameborder="0" scrolling="no" src="../form_hojokin/index.php" id="FormTool_f050" width="100%" class="mt-6"></iframe> 
</template> 
  
<script>
export default {
  name: 'parts-contact-iframe',
  data() {
    return {
    }
  },
}
</script>