<template>

  <div class="p-3 m-3">
    <p class="title is-5 has-text-left mb-6 pb-6">
      2022年度『木とふれあう木育推進事業』についてのよくある質問は公式サイトよりご確認ください
    </p>
  
    <div class="mb-3 pb-3">
      <a href="https://www.pref.osaka.lg.jp/hodo/index.php?site=fumin&pageId=44550" target="_blank">
      令和4年度木とふれあう木育推進事業の募集について
      <span class="icon"><i class="fa-solid fa-up-right-from-square"></i></span></a>
    </div>
    <div class="mt-3 pt-3">
      <a href="https://www.pref.osaka.lg.jp/attach/217/00424217/Q&A.pdf" target="_blank">
      令和４年度 木とふれあう木育推進事業の募集に関する Q＆A
      <span class="icon"><i class="fa-solid fa-up-right-from-square"></i></span></a>
    </div>
  </div>

</template>

<script>
import reduce from 'lodash/reduce';
//import PartsAccordionBox from './PartsAccordionBox.vue';

import config from './../config.json';

export default {
  name: 'parts-faq-link',
  components: {
    //PartsAccordionBox,
  },
  data() {
    return {
      isShows: {},
    };
  },
  computed: {
    QAs() {
      return config.QAS;
    }
  },
  created() {
    reduce(this.QAs, (results, value, key) => {
      results[key] = false;
      return results;
    }, this.isShows);
  }
}
</script>

<style scoped>
.QA_Q:before {
  content: url("../assets/img/icon_Q.png");
}

.QA_A:before {
  content: url("../assets/img/icon_A.png");
}
</style>
