<template>
  <h3 class="title is-3 has-text-centered py-6 my-6">何を導入したいですか？</h3>

  <div class="columns is-multiline">
    <div class="column is-4 is-relative is-clickable" v-for="(item, index) in CONTENTS" :key="index" @click="move(item.caption)">
      <figure class="image is-4by3">
        <img :src="item.src" :alt="item.alt">
      </figure>
      <div class="centering has-background-theme-grey p-1 is-flex is-justify-content-space-evenly" style="width:65%;top:90%;left:65%">
        <span class="has-text-weight-bold">{{item.caption}}</span>
        <span class="icon"><i class="fas fa-angle-right"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import { UTIL } from './../composables/util';

const CONTENTS = [
  {
    "src": "./static_img/counsultation_01.jpg",
    "alt": "下駄箱",
    "caption": "下駄箱"
  },
  {
    "src": "./static_img/counsultation_02.jpg",
    "alt": "ロッカー",
    "caption": "ロッカー"
  },
  {
    "src": "./static_img/counsultation_03.jpg",
    "alt": "テーブル",
    "caption": "テーブル"
  },
  {
    "src": "./static_img/counsultation_04.jpg",
    "alt": "椅子",
    "caption": "椅子"
  },
  {
    "src": "./static_img/counsultation_05.jpg",
    "alt": "ひのきプール",
    "caption": "ひのきプール"
  },
  {
    "src": "./static_img/counsultation_06.jpg",
    "alt": "ベビーゲート",
    "caption": "ベビーゲート"
  },
  {
    "src": "./static_img/counsultation_07.jpg",
    "alt": "おもちゃ",
    "caption": "おもちゃ"
  },
  {
    "src": "./static_img/counsultation_08.jpg",
    "alt": "絵本棚",
    "caption": "絵本棚"
  },
  {
    "src": "./static_img/full_order_getabako.png",
    "alt": "その他設備",
    "caption": "その他設備"
  },
];

export default {
  name: 'parts-consultation',
  setup() {
    const { scrollTo } = UTIL();
    return {
      scrollTo,
    };
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  },
  methods: {
    move(target) {
      const inner_d = document.getElementById('FormTool_f050').contentWindow.document;
      Array.from(inner_d.querySelector('[name="p001"]').options).forEach((el, n, all) => {
          const value = el.value.trim();
          if (value === target) {
              all[n].selected = true;
          }
      });
      this.scrollTo('contact');
    }
  }
}
</script>

<style scoped>
</style>
