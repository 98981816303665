<template>
  <div class="" v-for="(item, index) in CONTENTS" :key="index">
    <div class="has-background-white-transparent is-hidden-mobile w-50">
      <div class="content p-6">
        <span class="tag is-black">{{item.caption}}</span>
        <p class="title is-5 my-4">{{item.title}}</p>
        <p class="is-size-6 lh-4_1" v-html="item.text"></p>
      </div>
    </div>
    <div class="has-background-white-transparent is-hidden-tablet w-100">
      <div class="content p-6">
        <span class="tag is-black">{{item.caption}}</span>
        <p class="title is-5 my-3">{{item.title}}</p>
        <p class="is-size-6 lh-4_1" v-html="item.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';

const CONTENTS = [
  {
    "caption": "セミオーダーブランド",
    "title": "IKONIH（アイコニー）とは",
    "text": "「日本の豊かな森林環境を、次世代の子どもたちに残したい」<br>大阪府内産木材・国産ヒノキで作る『無塗装・安心・安全』なセミオーダー家具・玩具を子育て施設さまにご提供しています。",
  }
];

export default {
  name: 'parts-about-semi-order-brand',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
.has-background-white-transparent {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
