<template>
  <div class="columns is-gapless" :class="((index % 2) === 0) ? 'is-flex-direction-row-reverse': ''" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column">
      <figure class="image">
        <img class="object-fit-contain" :src="item.src" :alt="item.alt">
      </figure>
    </div>
    <div class="column has-background-white">
      <div class="content px-6 p-6-mobile">
        <p class="has-text-theme-green is-size-7 has-text-weight-bold mb-2">{{item.caption}}</p>
        <p class="title is-3 lh-6" v-html="item.title"></p>
        <p class="title is-5" v-html="item.subtitle"></p>
        <p class="is-size-6 lh-4_1" v-html="item.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';

const CONTENTS = [
  {
    "src": "./static_img/img01-02.png",
    "alt": "私たちの想い",
    "caption": "私たちの想い",
    "title": "大阪を森林の浴する街へ",
    "subtitle": "『子どもたちが木と触れ合う環境』を作っています",
    "text": "私たちは『大阪を森林の浴する街へ』のビジョンの元、大阪府下の子育て施設さまと<br>一緒に『子どもたちが木と触れ合う環境』を創ってきました。<br><br>大阪には先人たちが命懸けでつないできた「確かな山」からの産物<br>”ほんものの木”を子育て施設さまにお届けしてきました。<br><br>ほんものの木は”つながり”を与えてくれる資源です<br><br>私たちがほんもの木をお届けすることで<br>子育て施設さまで過ごす子どもたちに『木にと触れ合う環境』を作り<br>未来の子どもたちに豊かな森林資源を繋いでいきたいと考えています。",
  }
];

export default {
  name: 'parts-about-us',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
</style>
