<template>
  <section class="hero is-fullheight is-relative">
    <div class="hero-body p-0 is-hidden-mobile">
      <div class="fullscreen slideshow is-pc"
        :style="`background-image:linear-gradient(to right, ${rightRgba} 0%, ${rightRgba} 50%, ${leftRgba} 50%, ${leftRgba} 100%), url(${image.src});`"
        v-for="(image, index) in firstViewImages" :key="index">
      </div>
    </div>
    <div class="hero-body p-0 is-hidden-tablet">
      <div class="fullscreen slideshow is-sp"
        :style="`background-image:linear-gradient(to right, ${rightRgba} 0%, ${rightRgba} 50%, ${rightRgba} 50%, ${rightRgba} 100%), url(${image.src});`"
        v-for="(image, index) in firstViewImages" :key="index">
      </div>
    </div>
    <div class="has-text-white centering fv-caption">
      <p class="title is-3 lh-6 has-text-white p-3 is-hidden-mobile">子どもたちが『木と触れ合う環境』を作りませんか。<br>大阪で育った”ほんものの木”を使い、地元の子どもたちを育てる『木育』</p>
      <p class="title is-4 lh-5 has-text-white p-3 is-hidden-tablet">子どもたちが『木と触れ合う環境』を作りませんか。<br>大阪で育った”ほんものの木”を使い、地元の子どもたちを育てる『木育』</p>
      <hr class="has-background-white fv-hr">
      <p class="sub-title p-3">
      私たちは『 木のチカラで人々を幸せに。』の理念を掲げ、<br>
      国産木材を扱う材木店です。<br>
      今、子育て施設さまで使える補助金があります、<br>
      これを機会に私たちと子どもたちの木育に取り組んでいきませんか。<br>
      </p>
      <div class="buttons is-justify-content-space-evenly mt-3 is-hidden-mobile">
        <PartsButtonBlack @click="scrollTo('about-us')">&emsp;&emsp;詳しくみる&emsp;&emsp;</PartsButtonBlack>
        <button @click="scrollTo('about')" type="button py-2 px-3" class="button is-normal is-rounded has-background-whtie has-text-theme-green has-text-weight-semibold">補助金で取り組める木育設備 <span class="icon ml-2 has-text-black"><i class="fas fa-angle-right"></i></span></button>
      </div>
      <div class="mt-3 is-hidden-tablet px-3">
        <PartsButtonBlack @click="scrollTo('about-us')" class="my-4">&emsp;&emsp;詳しくみる&emsp;&emsp;</PartsButtonBlack>
        <button @click="scrollTo('about')" type="button py-2 px-3" class="button is-normal is-rounded has-background-whtie has-text-theme-green has-text-weight-semibold w-100">補助金で取り組める木育設備 <span class="icon ml-2 has-text-black"><i class="fas fa-angle-right"></i></span></button>
      </div>
    </div>
  </section>
</template>

<script>
import config from './../config.json';
import { UTIL } from './../composables/util';

import PartsButtonBlack from './../components/PartsButtonBlack.vue';

export default {
  name: 'parts-first-view',
  setup() {
    const { scrollTo } = UTIL();
    return {
      scrollTo,
    };
  },
  components: {
    PartsButtonBlack,
  },
  data() {
    return {
      index: 0,
      firstViewImages: config.FIRST_VIEW_IMAGES,
      rightRgba: 'rgba(0, 89, 49, 0.5)',
      leftRgba: 'rgba(0, 0, 0, 0)'
    };
  },
  mounted() {
    const pcImages = document.querySelectorAll('.slideshow.is-pc');
    const spImages = document.querySelectorAll('.slideshow.is-sp');
    setInterval(() => {
      this.index = this.index < (this.firstViewImages.length - 1) ? this.index + 1 : 0;
      this.slideshow(pcImages);
      this.slideshow(spImages);
    }, 3000);
  },
  methods: {
    slideshow(images) {
      const current = images[this.index];
      const prev = images[this.index - 1] ? images[this.index - 1] : images[images.length - 1];
      current.classList.add('fadein');
      current.classList.remove('fadeout');
      prev.classList.remove('fadein');
      prev.classList.add('fadeout');
    }
  }
}
</script>

<style scoped>
.fadein {
  opacity: 1;
  transition: opacity 0.9s;
}

.fadeout {
  opacity: 0;
  transition: opacity 0.9s;
}

.slideshow {
  position: absolute;
}

.fullscreen {
  width: 100%;
  height: 100vh; /* 全画面表示 */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>
