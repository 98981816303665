<template>
  <div class="columns is-multiline is-relative" v-for="chunkIndex in [0, 1]" :key="chunkIndex" ref="index">
    <div class="divider centering w-80 has-background-theme-blue is-hidden-mobile" :style="(chunkIndex === 1) ? {top:'60%'}: {top:'55%'}"></div>

    <template v-for="(item, index) in contents[chunkIndex]" :key="index">
      <div class="column is-3" :class="chunkIndex === 1 ? 'mt-6 pt-3': ''">
        <figure class="image is-64x64 m-auto">
          <img class="object-fit-contain" :src="item.step_src" :alt="item.alt">
        </figure>
        <figure class="image is-256x256 m-auto is-hidden-mobile w-70">
          <img class="object-fit-contain" :src="item.icon_src" :alt="item.alt">
        </figure>
        <figure class="image is-256x256 m-auto is-hidden-tablet w-40">
          <img class="is-rounded" :src="item.icon_src" :alt="item.alt">
        </figure>
        <p class="has-text-centered title is-5" v-html="item.comment"></p>
      </div>

    </template>
  </div>
  <p class="has-text-centered is-size-7 pt-6 mt-5">ご発注後、保育施設様から大阪府に提出する各種補助金申請書類の対応については、弊社が徹底的にサポートさせて頂きます。</p>
</template>

<script>
import chunk from 'lodash/chunk';

const CONTENTS = [
  {
    "step": "01",
    "icon_src": "./static_img/icon01.png",
    "step_src": "./static_img/01.png",
    "alt": "問い合わせ",
    "comment": "問い合わせ"
  },
  {
    "step": "02",
    "icon_src": "./static_img/icon02.png",
    "step_src": "./static_img/02.png",
    "alt": "ヒアリング（オンライン化）",
    "comment": "ヒアリング<br>（オンライン化）"
  },
  {
    "step": "03",
    "icon_src": "./static_img/icon03.png",
    "step_src": "./static_img/03.png",
    "alt": "ご提案",
    "comment": "ご提案"
  },
  {
    "step": "04",
    "icon_src": "./static_img/icon04.png",
    "step_src": "./static_img/04.png",
    "alt": "商品決定",
    "comment": "商品決定"
  },
  {
    "step": "05",
    "icon_src": "./static_img/icon05.png",
    "step_src": "./static_img/05.png",
    "alt": "発注",
    "comment": "発注"
  },
  {
    "step": "06",
    "icon_src": "./static_img/icon06.png",
    "step_src": "./static_img/06.png",
    "alt": "納品",
    "comment": "納品"
  },
  {
    "step": "07",
    "icon_src": "./static_img/icon07.png",
    "step_src": "./static_img/07.png",
    "alt": "書類提出",
    "comment": "書類提出"
  },
  {
    "step": "08",
    "icon_src": "./static_img/icon08.png",
    "step_src": "./static_img/08.png",
    "alt": "現地調査（大阪府職員）",
    "comment": "現地調査<br>（大阪府職員）"
  },
];

export default {
  name: 'parts-flow',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    contents() {
      return chunk(CONTENTS, 4);
    }
  }
}
</script>

<style scoped>
.divider {
  height: 2px;
}
</style>
