<template>
  <div class="" v-for="(item, index) in CONTENTS" :key="index">
    
    <figure class="image is-relative mt-3">
      <img :src="item.src" :alt="item.alt">
       <img class="object-fit-contain w-55 centering" src="../assets/img/semi_order.png" alt="セミオーダー家具" style="width:50%;top:0%;left:25%;">
    </figure>
    
    <div class="column has-background-white p-6">
      <div class="content has-text-centered">
        <p class="title is-3">{{item.title}}</p>
        <p class="has-text-centered" v-html="item.text"></p>
      </div>
    </div>
    
    <div class="has-text-centered">
      <PartsButtonBlack @click="scrollTo('facilities-semi-order')">木育設備事例を見る</PartsButtonBlack>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';
import { UTIL } from './../composables/util';
import PartsButtonBlack from './../components/PartsButtonBlack.vue';

const CONTENTS = [
  {
    "src": "./static_img/about_semi_order.jpg",
    "alt": "セミオーダー家具",
    "title": "カタログから選ぶだけ。IKONIHブランドのセミオーダー家具",
    "text": "大阪府内産木材のヒノキで作られたIKONIH家具がある教室は入るたびに<br>ヒノキの優しい香りが広がり、自然な明るさで開放感を感じさせてくれます。<br>ロッカー・仕切り棚・表差し絵本棚・折りたたみワイドテーブルなど<br>カタログに記載されている製品からお選びいただきます。",
  }
];

export default {
  name: 'parts-about-semi-order',
  setup() {
    const { scrollTo } = UTIL();
    return {
      scrollTo,
    };
  },
  components: {
    PartsButtonBlack,
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
</style>
