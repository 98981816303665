<template>
  <div class="box box-shadow-none convex mb-0">
    <div class="is-flex is-justify-content-center">
      <span class="tag is-black">補助金</span>
      <span class="has-text-theme-green title is-5 px-3">2022年度 『木とふれあう木育推進事業』 申請締め切り 2022年7月31日について</span>
    </div>
    <p class="has-text-centered mt-4 p-4">
      大阪府内の子育て施設で木育を目的に使うことができる補助金です。<br>
      補助金は来年以降も実施される保障がないため検討される施設様は早めのご相談をお願いいたします。<br>
      ※2021年度の『木とふれあう木育推進事業』補助金認可は全て弊社経由での申請案件となっております。
    </p>
  </div>
  
  <div class="columns is-gapless pt-6" :class="((index % 2) === 1) ? 'is-flex-direction-row-reverse': ''" v-for="(item, index) in CONTENTS" :key="index">
    <div class="column">
      <figure class="image">
        <img class="object-fit-contain" :src="item.src" :alt="item.alt">
      </figure>
    </div>
    <div class="column has-background-theme-grey">
      <div class="content px-6 p-6-mobile">
        <p class="has-text-theme-green is-size-7 has-text-weight-bold mb-2">{{item.caption}}</p>
        <p class="title is-3 lh-6" v-html="item.title"></p>
        <p class="title is-5" v-html="item.subtitle"></p>
        <p class="is-size-6 lh-4_1" v-html="item.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';
const CONTENTS = [
  {
    "src": "./static_img/img01-01.jpg",
    "alt": "世の中の流れ",
    "caption": "世の中の流れ",
    "title": "脱炭素化の流れで国内では木質化が進行中",
    "subtitle": "大阪府の補助金がある、今が木育（子どもたちが木に触れる環境）に取り組むべきです。",
    "text": "日本では脱炭素化を実現するために、国内産木材を活用して公共施設・教育環境などの木質化や木製家具の導入が進められています。<br><br>私たちは『今なら使える補助金がある』情報を発信して、1つでも多くの子育て施設さまに<br>大阪で育った木を選んで欲しいと思っています。<br>",
  }
];

export default {
  name: 'parts-about-circumstance',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
.convex {
  margin-top:-14rem;
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
}
 
@media all and (min-width: 768px) and (max-width: 1024px) {
} 
@media all and (min-width: 480px) and (max-width: 768px) {
  .convex {
    margin-top: inherit;
  }
}
 
@media all and (max-width: 480px) {
  .convex {
    margin-top: inherit;
  }
}
</style>
