<template>
  <div class="" v-for="(item, index) in CONTENTS" :key="index">
    
    <figure class="image is-relative mt-3">
      <img class="object-fit-contain" :src="item.src" :alt="item.alt">
      <img class="object-fit-contain w-55 centering" src="../assets/img/full_order.png" alt="フルオーダー家具" style="width:50%;top:0%;left:25%;">
    </figure>
    
    <div class="column has-background-theme-grey p-6">
      <div class="content has-text-centered">
        <p class="title is-3">{{item.title}}</p>
        <p class="has-text-centered" v-html="item.text"></p>
      </div>
    </div>
    
    <div class="has-text-centered">
      <PartsButtonBlack @click="scrollTo('facilities')">木育設備事例を見る</PartsButtonBlack>
    </div>
  </div>
</template>

<script>
//import config from './../config.json';
import { UTIL } from './../composables/util';
import PartsButtonBlack from './../components/PartsButtonBlack.vue';

const CONTENTS = [
  {
    "src": "./static_img/about_full_order.jpg",
    "alt": "フルオーダー家具",
    "title": "先生の『欲しい』をカタチにするフルオーダー家具",
    "text": "今までの経験を元に子育て施設さまの「欲しい」をカタチにしてお届けいたします。<br>打ち合わせから納品、その後のメンテナンスまで全て私たちにお任せください。",
  }
];

export default {
  name: 'parts-about-full-order',
  setup() {
    const { scrollTo } = UTIL();
    return {
      scrollTo,
    };
  },
  components: {
    PartsButtonBlack,
  },
  data() {
    return {
    };
  },
  computed: {
    CONTENTS() {
      return CONTENTS;
    }
  }
}
</script>

<style scoped>
</style>
